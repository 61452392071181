(function() {

    function makeSortable (el, config) {
        return Sortable.create(el, config);
    }

    $.fn.sortable = function(config) {
        return this.each(function(i, el) {
            makeSortable(el, config)
        });
    }

})();