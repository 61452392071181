
// We need some JS to support managing the LISS calls

function showLiss($clickable, $number) {
	// $number can be an import or an export number
	agentcall('liss/liss.ajax.php', 'showLiss', 'v2', $clickable, $number);
}



function doLiss($lissid) {
	gmAlert("We re missing a doLiss() function for this LISS connection", "LISS missing function");
}



function editLiss( $lissid ) {
	agentcall('liss/liss.ajax.php', 'editLiss', 'v2', $lissid);
}
function saveLiss( $lissid ) {
    $parms = form_to_array("liss");
	agentcall('liss/liss.ajax.php', 'saveLiss', 'v2', $lissid, $parms);
}

function deleteLiss( $lissid ) {
	gmConfirm("Are you sure you want to delete this LISS connection?", "javascript:deleteLiss2(" + $lissid + ")", "javascript:showLiss()");
}
function deleteLiss2($lissid) {
	agentcall('liss/liss.ajax.php', 'deleteLiss2', 'v2', $lissid);
}


