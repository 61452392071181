(function() {

    var $sideMenu = $('.side-menu'),
        $sideMenuToggler = $('.side-menu-toggler');
    
    $sideMenuToggler.click(function() {
        $sideMenu.toggleClass('active')
        $sideMenuToggler.toggleClass('active')
    });

})();
