(function () {

    var $ajaxSpinner = $('.comp-ajax-spinner');
    
    // global ajax request handler
    window.ajax = function ajax(config) {
        if (!config.url || !config.method) {
            console.error('Ajax Error: the "url" and "method" parameters are required', config);
            return false;
        }

        config.args = config.args || {};
        config.success = config.success || window.v2;
        config.error = config.error || function() {};
        config.complete = config.complete || function() {};

        $ajaxSpinner.addClass('active');

        return $.ajax({
            url: "/ui/components/ajax.php",
            type: "POST",
            data: {
                file: config.url,
                method: config.method,
                args: config.args
            },
            success: config.success,
            error: config.error,
            complete: function() {
                $ajaxSpinner.removeClass('active');

                if (config.complete) {
                    config.complete()
                }
            }
        });
    }

    // for backwards compatability
    window.agentcall = function agentcall(url, method, success) {
        var args = Array.prototype.slice.call(arguments, 3) || [];

        return ajax({
            url: url,
            method: method,
            success: window[success],
            args: args
        });
    }

})();