(function() {

    var $helpToggle = $('[data-toggle=help]'),
        $helpClose = $('[data-dismiss=help]');
    
    $helpToggle.click(function() {
        var $helpModal = $($(this).data('target'));
        $helpModal.addClass('show');
    });

    $helpClose.click(function() {
        var $helpModal = $(this).closest('.help-modal');
        $helpModal.removeClass('show');
    });

})();
