
// When a date field is used we need to schedule this function to run

function gmDateFields() {
    var $dobInputs = $('input[type=dob]'),
        $dateInputs = $('input[type=date]'),
        $dateTimeInputs = $('input[type=datetime-local]'),
        $dateRangeInputs = $('input[type=daterange]'),
        $timeInputs = $('input[type=time]');
    
    $dobInputs.each(function(i, el) {
        if (window.innerWidth > 1024) {
            new Cleave(el, {
                date: true,
                datePattern: ['d', 'm', 'Y']
            });
        } else {
            $(el).attr('type', 'date')
        }
    });

    $dateInputs.flatpickr({
        altInput: true,
        onOpen: function(selectedDates, dateStr, instance) {
            $(instance.calendarContainer).find(".numInputWrapper span").css("opacity","0.6");
        },
        onReady: function(selectedDates, dateStr, instance) {
            instance.altInput.id = instance.input.id + '_alt';
            instance.altInput.name = 'alt_' + instance.input.name;
            instance.set("minDate", $(instance.input).data("min"));
            instance.set("maxDate", $(instance.input).data("max"));
        }
    
    });

    $dateTimeInputs.flatpickr({
        altInput: true,
        enableTime: true,
        onOpen: function(selectedDates, dateStr, instance) {
            $(instance.calendarContainer).find(".numInputWrapper span").css("opacity","0.6");
        },
        onReady: function(selectedDates, dateStr, instance) {
            instance.altInput.id = instance.input.id + '_alt';
            instance.altInput.name = 'alt_' + instance.input.name;
            instance.set("minDate", $(instance.input).data("min"));
            instance.set("maxDate", $(instance.input).data("max"));
        }
    });

    // $rangeHelp = $.parseHTML("<div><p class='help'><b>HELP:</b> Click on the first date; then click the last date</p></div>");
    // $rangeHelp = '<div><p class="help"><b>HELP:</b> Click on the first date; then click the last date</p></div>';
    $dateRangeInputs.flatpickr({
        altInput: true,
        mode: 'range',
        onOpen: function(selectedDates, dateStr, instance) {
            if ($(instance.calendarContainer).find(".alert").length == 0) {
                $(instance.calendarContainer).append("<div class='alert alert-success'><strong>NOTE:</strong> Click on the first date;<br/>then click on the last date</div>");
                $(instance.calendarContainer).find(".numInputWrapper span").css("opacity","0.6");
            }
        },
        onReady: function(selectedDates, dateStr, instance) {
            instance.altInput.id = instance.input.id + '_alt';
            instance.altInput.name = 'alt_' + instance.input.name;
            instance.set("minDate", $(instance.input).data("min"));
            instance.set("maxDate", $(instance.input).data("max"));
        }
    });

    $timeInputs.flatpickr({
        altInput: true,
        enableTime: true,
        noCalendar: true
    });

}



