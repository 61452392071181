(function() {

    var draggables = [];

    function isDraggable(el) {
        return el && el.clientWidth > el.parentNode.clientWidth
    }

    function makeDraggable(el) {
        if (Draggable.get(el)) {
            return;
        }

        var container = el.parentElement;
        
        Draggable.create(el, {
            type: 'x',
            edgeResistance: 0.8,
            dragClickables: true,
            bounds: container
            // throwProps: true
        });

        var d = Draggable.get(el);

        if (isDraggable(d.target)) {
            d.enable();
        } else {
            d.disable();
        }

        draggables.push(d);
    }

    window.addEventListener('resize', function() {
        for (var i = 0; i < draggables.length; i++) {
            if (isDraggable(draggables[i].target)) {
                draggables[i].enable();
            } else {
                draggables[i].disable();
            }
        }
    });

    $.fn.draggable = function() {
        return this.each(function(i, el) {
            makeDraggable(el)
        });
    }

})();