// gm-forms Utilities
// gm-forms Utilities
// gm-forms Utilities

// The order of calling:
// 1. initForm()
// 2. [jslines]: Additional validation rules and javascript from the Ajax call
// 3. (3 seconds) Enable the submit button if it is still on the form


// Immediately after the form is loaded we call this function
function initForm(formId) {
	var $form = $('#' + formId),
		$btnSubmit = $form.find('button[type=submit]'),
		$modal = $form.closest('.modal');

	validate(formId);

	$form.submit(function(e) {
		if (!e.isDefaultPrevented()) {
			$btnSubmit.attr('disabled', true);

			// Wait 3 seconds and then automatically enable the button again
			setTimeout("enableSubmit(\""+formId+"\")", 3000);

			if ($modal) {
                if ($btnSubmit.hasClass("no-dismiss"))
                    ;
                else
				    $modal.modal('hide');
			}
		}
	});
}

function enableSubmit(formId) {

	// There is a bug in validation, it doesn't like the 'step' attribute on the date input
	var isMobile = window.matchMedia("only screen and (max-width: 760px)");
	if (isMobile.matches) {
		// console.log("isMobile = true");
		$('input[type="date"][step="any"]').removeAttr('step');
	}

	// Enable the submit button
	var $btnSubmit = $('#' + formId).find('button[type=submit]');
	if ($btnSubmit)
		$btnSubmit.attr('disabled', false);
}

// Run the form validation but only if we find a JS function associated with the form
function validate(formname) {
	var validFunction = "validate" + capitalizeFirstLetter(formname);
	if (window[validFunction]) {
		window[validFunction]();
	}
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// A form button to initiate the fileUploadDialog
// ffname is the form fieldname; eg gm_filename
function clickFileUpload(uniqueCount) {
	if (window["fireFileUpload"])
		fireFileUpload(uniqueCount);
}



// Focus on a form field
function focusOn($idname) {
	setTimeout("$('" + $idname + "').focus()", 500);
}


// This is run when the page loads...  so it should apply to all validation, even that added within a dialog
if ($.validator) {
	$.validator.addMethod("passwordChars", function(value, element) {
		return this.optional(element) || /^[-\w\s\.,\?_\$\*\(\)#@!%\/]+$/i.test(value);
	}, "Please use only characters from this list: a-z A-Z 0-9 . , ? - _ $ * ( ) # @ ! % /");

	$.validator.addMethod(
		    "regex",
		    function(value, element, regexp) {
		    	var re = new RegExp(regexp);
		        return this.optional(element) || re.test(value);
	}, "The input does not match the pattern we are expecting" );
	
	// Define a new jQuery Validator method
	$.validator.addMethod("fulladdress", AddressValidator);


	$.validator.setDefaults({
		errorElement: 'p',
		errorClass: 'form-control-danger',
		validClass: 'form-control-success',
		errorPlacement: function($error, $el) {
			var $parent = $el.parent(),
				$formGroup = $el.closest('.form-group');

			if ($parent.hasClass('form-grid')) {
				$el = $parent;
			}

			$error.removeClass('form-control-danger');
			$error.addClass('form-control-feedback');

			$formGroup.find('.form-control-feedback').remove();
			$formGroup.append($error);
		},
		highlight: function(el, errorClass, validClass) {
			var $el = $(el),
				$formGroup = $el.closest('.form-group');

			$el.removeClass(validClass);
			$el.addClass(errorClass);
			$formGroup.removeClass('has-success');
			$formGroup.addClass('has-danger');
		},
		unhighlight: function(el, errorClass, validClass) {
			var $el = $(el),
				$formGroup = $el.closest('.form-group');

			$el.removeClass(errorClass);
			$el.addClass(validClass);
			$formGroup.removeClass('has-danger');
			$formGroup.addClass('has-success');

			$formGroup.find('.form-control-feedback').remove();
		}
	});
}



// Create a jQuery exists method
jQuery.fn.exists = function () { return jQuery(this).length > 0; }

// Address jQuery Validator
function AddressValidator(value, element, paras) {

	console.log("we have waited enough, now validate " + value);
	  // Convert the value variable into something a bit more descriptive
	  var CurrentAddress = value;
	
	  /*
	   * If the address is blank, then this is for the required validator to deal
	   * with.
	   */
	  if (value.length == 0) {
		  return true;
	  }
	
	  /*
	 * If we've already validated this address, then just return the previous
	 * result
	 */
	  if ($(element).data("LastAddressValidated") == CurrentAddress) {
		  return $(element).data("IsValid");
	  }
	
	  /*
	 * We have a new address to validate, set the IsChecking flag to true and
	 * set the LastAddressValidated to the CurrentAddress
	 */
	  $(element).data("IsChecking", true);
	  $(element).data("LastAddressValidated", CurrentAddress);
	
	  /* Google Maps doesn't like line-breaks, remove them */
	  CurrentAddress = CurrentAddress.replace(/\n/g, "");
	
	  /* Create a new Google geocoder */
	  var geocoder = new google.maps.Geocoder();
	  geocoder.geocode({ 'address': CurrentAddress }, function (results, status) {
	
	/*
	 * The code below only gets run after a successful Google service call has
	 * completed. Because this is an asynchronous call, the validator has
	 * already returned a 'true' result to supress an error message and then
	 * cancelled the form submission. The code below needs to fetch the true
	 * validation from the Google service and then re-execute the jQuery form
	 * validator to display the error message. Futhermore, if the form was being
	 * submitted, the code below needs to resume that submit.
	 */
	
	// Google reported a valid geocoded address
	if (status == google.maps.GeocoderStatus.OK) {
	
	  // Get the formatted Google result
	  var address = results[0].formatted_address;
	
	  /*
		 * Count the commas in the fomatted address. This doesn't look great,
		 * but it helps us understand how specific the geocoded address is. For
		 * example, "CA" will geocde to "California, USA".
		 */
	  numCommas = address.match(/,/g).length;
	
	  /*
		 * A full street address will have at least 3 commas. Alternate
		 * techniques involve fetching the address_components returned by Google
		 * Maps. That code looked even more ugly.
		 */
	  if (numCommas >= 2) {
	
	    /* Replace the first comma found with a line-break */
	    address = address.replace(/, /, "\n");
	
	    // Set the textarea value to the geocoded address
	    // $(element).val(address);
	
	    // Cache this latest result
	    $(element).data("LastAddressValidated", address);
	
	    // We have a valid geocoded address
	    $(element).data("IsValid", true);
	    
	    addressValid = true;
		console.log("Formal Address = " + address);
		
	  } else {
	    /*
		 * Google Maps was able to geocode the address, but it wasn't specific
		 * enough (not enough commas) to be a valid street address.
		 */
	    $(element).data("IsValid", false);
	    
	    console.log("missing commas : address = " + address);
	    
	  }
	
	  // Otherwise the address is invalid
	} else {
	  $(element).data("IsValid", false);
	  
	  console.log("status = " + status);
	}
	
	// We're no longer in the midst of validating
	$(element).data("IsChecking", false);
	
	// Get the parent form element for this address field
	var form = $(element).parents('form:first');
	
	/*
	 * This code is being run after the validation for this field, if the form
	 * was being submitted before this validtor was called then we need to
	 * re-submit the form.
	 */
	if ($(element).data("SubmitForm") == true) {
	  form.submit();
	} else {
	  // Re-validate this property so we can return the result.
	     form.validate().element(element);
	    }
	  });

	/*
	 * The Address validator always returns 'true' when initially called. The
	 * true result will be return later by the geocode function (above)
	 */
	return true;
}









function form_to_array(frmname) {
	var frm = eval("document.forms." + frmname);
	return form_to_array2(frm, frmname);
}

function form_to_array2(frm, frmname) {
	var result = new Array();
	if (!frm) {
		result.push("ERR: Form " + frmname + " not found");
		return result;
	}
	for (ix = 0; ix < frm.elements.length; ix++) {
		var elem = frm.elements[ix];
		if (!elem.disabled) {
			result.push("~~" + elem.name);
			switch (elem.type) {
			case "select":
				result.push(elem.options[elem.selectedIndex].value);
				break;
			case "checkbox":
				if (elem.checked)
					result.push('true');
				else
					result.push('false');
				break;
			case "radio":
				if (elem.checked)
					result.push(elem.value);
				else
					result.pop();
				break;
			default:
				result.push(elem.value);
				break;
			}
		}
	}
	return result;
}



// Confirm dialog
function gmConfirm(question, yes, no) {
	var content = 
		"<div class=\"modal-dialog $modalSize\" role=\"document\">" +
			"<div class=\"modal-content\">" +
				"<div class=\"modal-header\">" +
					"<h5 class=\"modal-title\">Confirm</h5>" +
				"</div>" +
				"<div class=\"modal-body\">" + question + "</div>" +
				"<div class=\"modal-footer\">" +
					"<button type=\"button\" class=\"btn btn-outline-primary confirm-yes\" data-dismiss=\"modal\" onclick=\"" + yes + "\" >Yes</button>" +
					"<button type=\"button\" class=\"btn btn-outline-primary\" data-dismiss=\"modal\" onclick=\"" + no + "\" >No</button>" +
				"</div>" +
			"</div>" + 
		"</div>";
	v2(["DialogPlain", content, "J", "$(\".confirm-yes\").focus()"]);
}

// Alert dialog
function gmAlert(message, title) {
	title = title || "Warning";
	var content = 
		"<div class=\"modal-dialog $modalSize\" role=\"document\">" +
			"<div class=\"modal-content\">" +
				"<div class=\"modal-header\">" +
					"<h5 class=\"modal-title\">" + title + "</h5>" +
				"</div>" +
				"<div class=\"modal-body\">" + message + "</div>" +
				"<div class=\"modal-footer\">" +
					"<button type=\"button\" class=\"btn btn-outline-primary alert-ok\" data-dismiss=\"modal\" onclick=\"v2(['DialogHide'])\" >Ok</button>" +
				"</div>" +
			"</div>" + 
		"</div>";
	v2(["DialogPlain", content, "J", "$(\".alert-ok\").focus()"]);
}


function dismissDialog() {
	v2(['DialogHide']);
}


// Convert select field to text field
function convertSelectToText(obj, name2) {
	var ix = obj.selectedIndex;
	var val = obj.options[ix].value;
	$(obj).replaceWith("<input class='form-control' type='text' id='" + name2 + "' name='" + name2 + "' value='" + val + "'/>");
}





// I don't think we are using any of the functions below...
// I don't think we are using any of the functions below...
// I don't think we are using any of the functions below...


// Validate the entered date - in case the date picker isn't used
function validDate(dmy) {
	var arrdmy = dmy.split("-");
	switch (defaultDateFormat) {
	case "dmy":
		if (!numericLimits(arrdmy[0],1,31))
			return false;
		if (!numericLimits(arrdmy[1],1,12))
			return false;
		if (!numericLimits(arrdmy[2],1,2035))
			return false;
		break;
	case "mdy":
		if (!numericLimits(arrdmy[1],1,31))
			return false;
		if (!numericLimits(arrdmy[0],1,12))
			return false;
		if (!numericLimits(arrdmy[2],1,2035))
			return false;
		break;
	}
	return true;
}
function numericLimits(src, min, max) {
	val = parseInt(src, 10);
	if (isNaN(val)) return false;
	if (val < min) return false;
	if (val > max) return false;
	return true;
}


// ColourFields support
function updatesample(colourfield) {
	var fg = document.getElementById("cpfore" + colourfield);
	var bg = document.getElementById("cpback" + colourfield);
	var samp = document.getElementById("cpsamp" + colourfield);
	if (fg && bg && samp) {
		var fgval = fg.value;
		var bgval = bg.value;
		samp.innerHTML = "<p style='margin: 0px; padding: 3px; height: 16px; width: 80px; border: 1px solid black; color: #" + fgval + "; background-color: #" + bgval + ";'>Sample...</p>";
	} else if (bg && samp) {
		var bgval = bg.value;
		if (bgval.indexOf(0) == "0")
			var fgval = "ffffff";
		else
			var fgval = "000000";
		samp.innerHTML = "<p style='margin: 0px; padding: 3px; height: 16px; width: 80px; border: 1px solid black; color: #" + fgval + "; background-color: #" + bgval + ";'>Sample...</p>";
	}
}

// CKEditor support
function ckactive(idname, hideid) {
	if (hideid) {
		elem = document.getElementById(hideid);
		if (elem)
			elem.style.display = "none";
	}
	CKEDITOR.replace(idname);
}

// If you use this, don't forget to enable hidden elements on the form
function initCKEditor(idname) {
    // console.log("initCkeditor for field " + idname);
    var textarea = $('#' + idname);
    var editor = CKEDITOR.replace(idname);

    editor.on('change', function(e) {
        // console.log(e);
        textarea.val(editor.getData());
    });

    editor.on('blur', function(e) {
        // console.log('blur event');
        textarea.trigger('blur');
    });
}

