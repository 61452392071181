// JS functions used for the Finder

function editMyYearLevels() {
    agentcall('ui/profile.ajax.php', 'editMyYearLevels', 'v2');
}





