(function() {

    var $window = $(window),
        $navbar = $('#navbar'),
        $navbarToggler = $navbar.find('.navbar-toggler'),
        $firstBlock = $('.block').first(),
        togglerActive = false,
        controller = new ScrollMagic.Controller(),
        duration = $navbar.height() / 2;

    if ($firstBlock.hasClass('block-primary') || $firstBlock.hasClass('block-dark')) {
        var darkScene = new ScrollMagic.Scene({
            triggerHook: 0,
            duration: duration
        }).setClassToggle($navbar, 'navbar-dark')
          .addTo(controller);

        var lightScene = new ScrollMagic.Scene({
            triggerHook: 0,
            offset: duration
        }).setClassToggle($navbar, 'navbar-light')
          .addTo(controller);

        $navbarToggler.click(function() {
            togglerActive = !togglerActive
            controller.enabled(!togglerActive);

            if (togglerActive) {
                $navbar.removeClass('navbar-dark');
                $navbar.addClass('navbar-light');
            } else {
                controller.update(true);

                if (darkScene.state() == 'DURING') {
                    $navbar.removeClass('navbar-light');
                    $navbar.addClass('navbar-dark');
                }
            }
        });
    } else {
        $navbar.removeClass('navbar-dark');
        $navbar.addClass('navbar-light');
    }

})();


function homeSwitch() {
	agentcall('ui/login.ajax.php', 'homeSwitch', 'v2');
}


// Some generic navigational options
function logoff() {
	agentcall('ui/login.ajax.php', 'logoff', 'v2');
}

