
// File Upload utility

/**
 * props { 
 * 		load : true, // Begin upload when document has loaded
 *  	change : 'elementid', // Begin upload when this element value changes
 *		click : 'buttonid',  // Begin upload when this button is clicked
 *		
 *  	callback : 'function()' // Call back on completion
 *  }
 */

function fileUpload(props) {
	
	// If it's not valid then just return
	if (!$("#make-upload-dialog").valid()) 
        return;
    
	// Ensure we have a props object
	props = props || {};
	
	var ifile = props.ifile || "ifile";  // This should identify the input file element
   	var fileinput = document.getElementById(ifile);
    var file = fileinput.files[0]; // Just the first file
    if (!file) {
    	console.log("File is not selected or is too large");
    	gmAlert("File is not selected or is too large", "Error");
    }
	
    var action = props.action || "/ui/upload.php";

    // Reference fields on form
    var progress = $(".progressbar");
    var bar = $(".bar");
    var percent = $(".percent");
    var status = $("#uploadstatus");


    // Build form
    var xhr = new XMLHttpRequest();
    var formData = new FormData();
    var caption = document.getElementById("caption");
    if (caption && caption.value)
    	formData.append("caption", caption.value);
    if (props.identifier)
		formData.append(props.identifier, file);
	else
		formData.append("fileUploadStart", file);
		
    // Add the progress listener
    xhr.addEventListener('progress', function(e) {
        status.empty();
        var percentVal = '0%';
        bar.width(percentVal);
        percent.html(percentVal);
        // console.log('xhr progress: ' + (Math.floor(done/total*1000)/10) + '%');
    }, false);
    
    if ( xhr.upload ) {
        xhr.upload.onprogress = function(e) {
			var done = e.position || e.loaded, total = e.totalSize || e.total
			var present = Math.floor(done/total*100)
			var percentVal = present + '%'
            // var percentVal = (e.loaded / e.total * 100) + '%';
            bar.width(percentVal);
            percent.html(percentVal);
            // console.log('xhr.upload progress: ' + done + ' / ' + total + ' = ' + (Math.floor(done/total*1000)/10) + '%');
        };
    }
    
    xhr.open('post', action, true);
    xhr.onreadystatechange = function(e) {
        if ( 4 == xhr.readyState ) {
            // console.log(['xhr upload complete', e]);
            // console.log("response = " + xhr.responseText);
			v2( JSON.parse(xhr.responseText) );
        }
    };
	xhr.send(formData);
}

 
var alertmsg = null;

function fileUploadOld(props) {	
	
	props = props || {};

	// The original version added a 'change' event listener to an element
	if (props.load) {
		// Could be the input file element or a description
		myElement = document;
		myEvent = "load";
	}											
	
	// The original version added a 'change' event listener to an element
	if (props.change) {
		// Could be the input file element or a description
		myElement = document.getElementById(props.change);
		myEvent = "change";
	}											
		
	// But how about a button?
	if (props.click) {
		// Could be the submit button
		// console.log("looking for " + props.click);
		$(props.click).on("click", function(e) {
		
			var fileinput = null;  // This should identify the input file element
			if (props.ifile)
		    	fileinput = document.getElementById(props.ifile);
		    else
		    	fileinput = document.getElementById("ifile");
		    var file = null;
		    if (fileinput)
		    	file = fileinput.files[0]; // Just the first file
		    if (!file)
		    	console.log("File is not selected or is too large");
		    	
		    var xhr = new XMLHttpRequest();
		    var progress = document.getElementById("progress");
		    // console.log(progress);
		    
		    var formData = new FormData();
		    
		    var caption = document.getElementById("caption");
		    if (caption && caption.value)
		    	formData.append("caption", caption.value);
		    	
		    if (props.identifier)
				formData.append(props.identifier, file);
			else
				formData.append("fileUploadStart", file);
				
		    xhr.addEventListener('progress', function(e) {
		        var done = e.position || e.loaded, total = e.totalSize || e.total;
		        pct = (Math.floor(done/total*1000)/10) * 2;
		        if (progress)
		        	progress.style.width = pct + "px"; 
		        // console.log('xhr progress: ' + (Math.floor(done/total*1000)/10) + '%');
		    }, false);
		    
		    if ( xhr.upload ) {
		        xhr.upload.onprogress = function(e) {
		            var done = e.position || e.loaded, total = e.totalSize || e.total;
		            pct = (Math.floor(done/total*1000)/10) * 2;
		            if (progress)
		            	progress.style.width = pct + "px"; 
		            // console.log('xhr.upload progress: ' + done + ' / ' + total + ' = ' + (Math.floor(done/total*1000)/10) + '%');
		        };
		    }
		    
		    xhr.onreadystatechange = function(e) {
		        if ( 4 == this.readyState ) {
		        	if (progress)
		            	progress.style.width = "200px"; 
		            // console.log(['xhr upload complete', e]);
		            var jsonArr = eval('(' + this.responseText + ')');
		            if (this.responseText == "Upload successfull") {
		            	// console.log("responseText: Upload successfull");
			            if (props.callback)
			            	eval(props.callback);
			            else
			            	console.log("File upload: " + this.responseText);
		            } else if (jsonArr instanceof Array) {
		            	// console.log("responseText: calling v2()");
			            // ModalDialog.cancel();
		            	v2(jsonArr);
		            } else {
		            	alertmsg = this.responseText;
		            	console.log("AlertMessage: " + alertmsg);
	            		setTimeout("gmAlert(alertmsg)",300);
		            }
		            
		            $( "#dialog-form" ).dialog('close');
		        }
		    };
		    
		    xhr.open('post', 'upload.php', true);
			xhr.send(formData);
			
			if (progress) {
				progress.style.width = "1px";
				progress.style.display = "block";
			}
		}, false);
	}
}


function uploadFormSubmit(props) {

			var fileinput = null;  // This should identify the input file element
			if (props.ifile)
		    	fileinput = document.getElementById(props.ifile);
		    else
		    	fileinput = document.getElementById("ifile");
		    var file = null;
		    if (fileinput)
		    	file = fileinput.files[0]; // Just the first file
		    if (!file)
		    	console.log("File is not selected or is too large");
		    	
		    var xhr = new XMLHttpRequest();
		    var progress = document.getElementById("progress");
		    
		    var formData = new FormData();
		    
		    var caption = document.getElementById("caption");
		    if (caption && caption.value)
		    	formData.append("caption", caption.value);
		    	
		    if (props.identifier)
				formData.append(props.identifier, file);
			else
				formData.append("fileUploadStart", file);
				
		    xhr.addEventListener('progress', function(e) {
		        var done = e.position || e.loaded, total = e.totalSize || e.total;
		        pct = (Math.floor(done/total*1000)/10) * 2;
		        if (progress)
		        	progress.style.width = pct + "px"; 
		        // console.log('xhr progress: ' + (Math.floor(done/total*1000)/10) + '%');
		    }, false);
		    
		    if ( xhr.upload ) {
		        xhr.upload.onprogress = function(e) {
		            var done = e.position || e.loaded, total = e.totalSize || e.total;
		            pct = (Math.floor(done/total*1000)/10) * 2;
		            if (progress)
		            	progress.style.width = pct + "px"; 
		            // console.log('xhr.upload progress: ' + done + ' / ' + total + ' = ' + (Math.floor(done/total*1000)/10) + '%');
		        };
		    }
		    
		    xhr.onreadystatechange = function(e) {
		        if ( 4 == this.readyState ) {
		        	if (progress)
		            	progress.style.width = "200px"; 
		            // console.log(['xhr upload complete', e]);
		            // console.log("xhr upload finished: this.responseText = " + this.responseText);
		            var jsonArr = eval('(' + this.responseText + ')');
		            if (this.responseText == "Upload successfull") {
		            	// console.log("responseText: Upload successfull");
			            if (props.callback)
			            	eval(props.callback);
			            else
			            	console.log("File upload: " + this.responseText);
		            } else if (jsonArr instanceof Array) {
		            	// console.log("responseText: calling v2()");
			            // ModalDialog.cancel();
		            	v2(jsonArr);
		            } else {
		            	alertmsg = this.responseText;
		            	console.log("AlertMessage: " + alertmsg);
	            		setTimeout("alert({content:alertmsg})",300);
		            }
		            $( "#dialog-form" ).dialog("close");
		        }
		    };
		    
		    xhr.open('post', 'upload.php', true);
			xhr.send(formData);
			
			if (progress) {
				progress.style.width = "1px";
				progress.style.display = "block";
			}

}
			