// Generic JS functions used across SOBS

function returnHome() {
    agentcall('ui/schools.ajax.php', 'returnHome', 'v2');
}

function logoffCurrentuser() {
    window.location.assign("/ui/home.php?logoff=true");
}

function selectAnApp() {
    window.location.assign("/ui/login.php");
}


// Ping the server to keep the session open
function sobsKeepAlive() {
	setTimeout("sobsKeepAlive2()", 600000); // 10 minutes
}
function sobsKeepAlive2() {
    agentcall('ui/keepalive.ajax.php', 'keepalive', 'v2');
}



// Go somewhere
// function goto($target) {
// 	return array("J", "window.location.assign(\"" + $target + "\")");
// }



// My do nothing function
function donothing() {
}


// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

